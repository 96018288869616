import {React, useState} from "react"
import {graphql, Link} from "gatsby"
import Layout from "../components/layout"
import Img from "gatsby-image";
import {Row, Col, Container, Form, FormControl, Button} from "react-bootstrap";
import blogStyles from "./blog.module.css";
import globalStyles from "../styles/global.module.css"

const BlogPage =  ({data, author}) => {
  const authorPosts = author ? data.allMarkdownRemark.edges.filter(post => post.node.frontmatter.author === author ) : data.allMarkdownRemark.edges;
  const allPosts = data.allMarkdownRemark.edges
  const emptyQuery = ""
  const [state, setState] = useState({
    filteredData: [],
    query: emptyQuery,
  })

  const handleInputChange = event => {
    console.log(event.target.value)
    const query = event.target.value;

    const filteredData = allPosts.filter(post => {
      // console.log(post.node.frontmatter.description)
      // const { description } = post.node.frontmatter.description
      // let title = post.node.frontmatter.title
      // const { description, title, tags } = post.node.frontmatter
      // console.log(description)
      // return (
        // description.toLowerCase().includes(query.toLowerCase()) ||
        // title.toLowerCase().includes(query.toLowerCase())
        // ||
    //     (tags &&
    //       tags
    //         .join("")
    //         .toLowerCase()
    //         .includes(query.toLowerCase()))
    //   )
    })
    setState({
      query,
      filteredData,
    })
  }

  const { filteredData, query } = state
  const hasSearchResults = filteredData && query !== emptyQuery
  const posts = hasSearchResults ? filteredData : allPosts

    return (
      <Layout className="site-content">
        <Container>
          <Row>
            <Col>
              <input
                className="searchInput"
                type="text"
                aria-label="Search"
                placeholder="Type to filter posts..."
                onChange={handleInputChange}
              />
            </Col>
          </Row>

          {/*{posts.map(({ node }) => {*/}
          {/*  const { excerpt } = node*/}
          {/*  const { slug } = node.fields*/}
          {/*  const { title } = node.frontmatter*/}
          {/*  return (*/}
          {/*    <article key={slug}>*/}
          {/*      <header>*/}
          {/*        <h2>*/}
          {/*          <Link to={slug}>{title}</Link>*/}
          {/*        </h2>*/}
          {/*      </header>*/}
          {/*      <section>*/}
          {/*        <p*/}
          {/*          dangerouslySetInnerHTML={{*/}
          {/*            __html: description || excerpt,*/}
          {/*          }}*/}
          {/*        />*/}
          {/*      </section>*/}
          {/*      <hr />*/}
          {/*    </article>*/}
          {/*  )*/}
          {/*})}*/}

          <Row>
            <Col>
              <h1 className={globalStyles.entryTitle}>MOMS Articles</h1>
              {authorPosts.map(({node}) => (
                <Row key={node.id } className={blogStyles.blogSection}>
                  <Col md={3}>
                    <div className={blogStyles.postThumbnail}>
                      <Link to={node.frontmatter.path} >
                        <Img  fluid={node.frontmatter.featuredImage.childImageSharp.fluid}/>
                      </Link>
                    </div>
                  </Col>
                  <Col md={9}>
                    <div className="blog-post-content">
                      <div className="entry-meta">
                        <Link to={node.frontmatter.path}>
                          <h1 className={blogStyles.blogTitle}>{node.frontmatter.title}</h1>
                        </Link>
                        <Link to={node.frontmatter.path}>
                          <span >{node.frontmatter.date }</span>
                        </Link>
                        <Link className="link-to-page"  to={"/author/" + node.frontmatter.author}>
                          <span>{node.frontmatter.author}</span>
                        </Link>
                      </div>
                    </div>
                  </Col>
                </Row>
              ))}
            </Col>
          </Row>
        </Container>
      </Layout>
    )
};

export const query = graphql`
  query {
    allMarkdownRemark (
        filter: {frontmatter: {posttype: {eq: "blogpost"}}},
        sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            author
            date(formatString: "DD MMMM, YYYY")
            path
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
                }
              }
            }
            description
          }
          excerpt
          html
        }
      }
    }
  }
`;

export default BlogPage
